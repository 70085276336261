import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  theme: Theme = Theme.LIGHT;
  getNewTheme: Subject<Theme> = new Subject<Theme>();

  constructor() {
  }

  setNewTheme(_checked: boolean): void {
    this.theme = _checked ? Theme.DARK : Theme.LIGHT;
    this.getNewTheme.next(this.theme);
  }
}

export class Theme {
  static readonly LIGHT = new Theme('Light', 'light-theme', 'bg-light');
  static readonly DARK = new Theme('Dark', 'dark-theme', 'bg-secondary');

  // private to disallow creating other instances of this type
  private constructor(
    public readonly name: string,
    public readonly css: string,
    public readonly bootstrap: string
  ) {
  }
}