export class Amiibo {
    public name       : string;
    public image      : string;
    public possession : boolean;
    public serie      : string;
    public number     : number;
    public available  : boolean;
    public release    : string;
  
    constructor(json:any) {
      this.name       = json.name;
      this.image      = json.image;
      this.serie      = json.serie;
      this.possession = json.possession;
      this.number     = json.number;
      this.available  = json.available;
      this.release    = json.release;
      this.checkReleaseDate();
    }

    checkReleaseDate(): void{
      switch(this.release){
        case "TBA" :
          this.available = false;
          break;
        case "dispo" :
          this.available = true;
          break;
        default :

          break;
      }
    }
}