import { Component, Input, OnInit } from '@angular/core';
import { Pokemon } from 'src/app/common/model/pokemon';
import { ThemeService } from 'src/app/common/theme/theme.service';

@Component({
  selector: 'pokemon-holder',
  templateUrl: './pokemon-holder.component.html',
  styleUrls: ['./pokemon-holder.component.scss']
})
export class PokemonHolderComponent implements OnInit {

  @Input() pokemon: Pokemon = {} as Pokemon;
  theme: string = "";

  constructor(private themeService: ThemeService) {
    this.theme = this.themeService.theme.bootstrap;
    this.themeService.getNewTheme.subscribe(_theme => this.theme = _theme.bootstrap);
   }

  ngOnInit(): void {
  }

}
