import { Component } from '@angular/core';
import { Theme, ThemeService } from './common/theme/theme.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title: string = 'mySite';
  theme: string = Theme.LIGHT.css;

  constructor(private themeService: ThemeService) {
    this.themeService.getNewTheme.subscribe(_theme => {
      this.changeBodyTheme(_theme);
      this.theme = _theme.css
    });
  }


  changeBodyTheme(_theme: Theme) {
    document.body.classList.remove(this.theme);
    document.body.classList.add(_theme.css);
  }
}
