<div class="container">
    <div class="row">
        <mat-button-toggle-group name="generation" aria-label="Generation" value="1"
            (change)="changeGeneration($event.value)">
            <mat-button-toggle class="col-sm-2" value="1">Genération 1</mat-button-toggle>
            <mat-button-toggle class="col-sm-2" value="2">Genération 2</mat-button-toggle>
            <mat-button-toggle class="col-sm-2" value="3">Genération 3</mat-button-toggle>
            <mat-button-toggle class="col-sm-2" value="4">Genération 4</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle-group name="shiny" aria-label="Chromatique" value="Normal"
            (change)="changeChromatique($event.value)">
            <mat-button-toggle class="col-sm-2" value="Normal">Normal</mat-button-toggle>
            <mat-button-toggle class="col-sm-2" value="Shiny">Chromatique</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>

<div class="container">
    <div class="row">
        <div *ngFor="let pokemon of listeGen" class="col-sm-1">
            <animated-pokemon [pokemon]="pokemon" [image]="chosenImage"
                [dimension]="generation.getDimension()"></animated-pokemon>
        </div>
    </div>
</div>