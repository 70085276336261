import { Component, Input, OnInit } from '@angular/core';
import { Manga } from 'src/app/common/model/manga';
import { ThemeService } from 'src/app/common/theme/theme.service';


@Component({
  selector: 'manga-holder',
  templateUrl: './manga-holder.component.html',
  styleUrls: ['./manga-holder.component.scss']
})
export class MangaHolderComponent implements OnInit {

  constructor(private themeService: ThemeService) {
    this.theme = this.themeService.theme.bootstrap;
    this.themeService.getNewTheme.subscribe(_theme => this.theme = _theme.bootstrap);
  }

  @Input() manga: Manga = new Manga({});
  theme: string = "";


  ngOnInit(): void {
  }
}
