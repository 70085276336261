import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Amiibo } from "src/app/common/model/amiibo";
import { JsonReaderService } from 'src/app/service/json/json-reader.service';

@Component({
  selector: 'app-amiibo',
  templateUrl: './amiibo.component.html',
  styleUrls: ['./amiibo.component.scss']
})
export class AmiiboComponent implements OnInit {

  amiiboList: Array<Amiibo> = [];
  currentPage: number = 1;
  test!: Observable<Amiibo[]>;

  constructor(private jsonReader: JsonReaderService) { }

  ngOnInit(): void {
    this.jsonReader.getAmiibosByPage2(this.currentPage).subscribe(result => {
      this.amiiboList = this.customizeApiAnswer(result);
    });
  }

  onScroll(): void {
    if (this.currentPage >= 12) {
      return;
    }
    this.jsonReader.getAmiibosByPage2(++this.currentPage).subscribe(result => {
      this.amiiboList = this.amiiboList.concat(this.customizeApiAnswer(result));
    });
  }


  customizeApiAnswer(result: JSON) {
    let amiiboList: Array<Amiibo> = Object.assign(result);
    amiiboList = amiiboList.map(amiibo => new Amiibo(amiibo));
    amiiboList.sort((a, b) => a.number - b.number);
    return amiiboList;
  }
}

