import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Manga } from 'src/app/common/model/manga';
import { JsonReaderService } from 'src/app/service/json/json-reader.service';

@Component({
  selector: 'app-volume-detail',
  templateUrl: './volume-detail.component.html',
  styleUrls: ['./volume-detail.component.scss']
})
export class VolumeDetailComponent implements OnInit {

  mangasList = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private jsonReader: JsonReaderService,
    private router: Router
  ) { }
  public id: string = "";
  public volumeList: Array<Manga> = [];
  public title: string = "";

  ngOnInit() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.id = this.activatedRoute.snapshot.paramMap.get('id') || "";

    this.jsonReader.getMangasList().subscribe(result => {
      let mangasList: Array<Manga> = Object.assign(result);
      let manga: Manga = mangasList.find(manga => manga.id === this.id) || new Manga({});
      if (!manga.id) {
        this.router.navigate(['/notFound']);
      }
      this.volumeList = this.createVolumeList(manga);
      this.title = manga.name;
    });
  }
  createVolumeList(_manga: Manga): Manga[] {
    let volumes: Array<Manga> = []
    for (let i = 0; i < _manga.lastTome; i++) {
      volumes.push(this.createNewVolume(_manga, i + 1));
    }
    return volumes;
  }

  private createNewVolume(manga: Manga, number: number): Manga {
    let volume: Manga = new Manga(manga);
    volume.name = "Tome " + number;
    volume.frontCover = number;
    return volume;
  }
}
