import { Component, Input, OnInit } from '@angular/core';
import { SpriteDimension } from 'src/app/common/model/generation';
import { Sprite } from 'src/app/common/model/pokemonSprite';

@Component({
  selector: 'animated-pokemon',
  templateUrl: './animated-pokemon.component.html',
  styleUrls: ['./animated-pokemon.component.scss']
})
export class AnimatedPokemonComponent implements OnInit {

  constructor() { }

  @Input() pokemon: Sprite = {} as Sprite;
  @Input() image: string = '';
  @Input() dimension: SpriteDimension = {} as SpriteDimension;
  test: boolean= false;

  ngOnInit(): void {
    let alea: number = Math.floor(Math.random() * 5) *100;
    if(!this.pokemon.isAnimated){
      return;
    }
    setTimeout(()=> {
      this.animerPokemon(800);
    }, alea);
  }

  animerPokemon(delai: number){
    setInterval(() => {
      this.pokemon.x += this.test ? this.dimension.nextAnimation : -this.dimension.nextAnimation;
      this.test = !this.test;
    }, delai);
  }

}
