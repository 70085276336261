import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PremierOngletComponent } from './page/premier-onglet/premier-onglet.component';
import { DeuxiemeOngletComponent } from './page/deuxieme-onglet/deuxieme-onglet.component';
import { FooterComponent } from './common/footer/footer.component';
import { HeaderComponent } from './common/header/header.component';
import { PageNotFoundComponent } from './page/page-not-found/page-not-found-component';
import { AccueilComponent } from './page/accueil/accueil.component';
import { AmiiboComponent } from './page/amiibo/amiibo.component';
import { AmiiboHolderComponent } from './component/amiibo-holder/amiibo-holder.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { data } from '../environments/firebase';
import { HttpClientModule } from '@angular/common/http';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MangaListComponent } from './page/manga-list/manga-list.component';
import { MangaHolderComponent } from './component/manga-holder/manga-holder.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { VolumeDetailComponent } from './page/volume-detail/volume-detail.component';
import { InfiniteScrollComponent } from './component/infinite-scroll/infinite-scroll.component';
import { AutreComponent } from './page/autre/autre.component';
import { PokemonComponent } from './page/pokemon/pokemon.component';
import { PokemonHolderComponent } from './component/pokemon-holder/pokemon-holder.component';
import { TetrisComponent } from './page/tetris/tetris.component';
import { Pokemon2Component } from './page/pokemon2/pokemon2.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { AnimatedPokemonComponent } from './component/animated-pokemon/animated-pokemon.component';
import { Pokemon4gComponent } from './page/pokemon4g/pokemon4g.component'; 


@NgModule({
  declarations: [
    AppComponent,
    PremierOngletComponent,
    DeuxiemeOngletComponent,
    FooterComponent,
    HeaderComponent,
    PageNotFoundComponent,
    AccueilComponent,
    AmiiboComponent,
    AmiiboHolderComponent,
    MangaListComponent,
    MangaHolderComponent,
    VolumeDetailComponent,
    InfiniteScrollComponent,
    AutreComponent,
    PokemonComponent,
    PokemonHolderComponent,
    TetrisComponent,
    Pokemon2Component,
    AnimatedPokemonComponent,
    Pokemon4gComponent
  ],
  imports: [
    BrowserModule,
    MatSlideToggleModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(data),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatButtonToggleModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
