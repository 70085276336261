export class Manga {
    public id         : string;
    public name       : string;
    public statut     : string;
    public own        : Array<number>;
    public lastTome   : number;
    public frontCover : number;
  
    constructor(json:any) {
      this.id         = json.id;
      this.name       = json.name;
      this.statut     = json.statut;
      this.own        = json.own;
      this.lastTome   = json.lastTome;
      this.frontCover = this.getFrontCoverNumber(); 
    }

    getFrontCoverNumber() : number {
      if(!this.own || this.own.length === 0) return 1;

      this.own.sort((a, b) => a - b);
      return this.own[this.own.length - 1];
    }

    isOwn() : boolean{
      return this.own.includes(this.frontCover);
    }
}

export enum Statut {
  soon = "A venir" ,
  over = "Fini",
  reading = "En cours",
  pause = "En pause"
}