import { Component, OnInit } from '@angular/core';
import { Pokemon } from 'src/app/common/model/pokemon';
import { JsonReaderService } from 'src/app/service/json/json-reader.service';

@Component({
  selector: 'app-pokemon',
  templateUrl: './pokemon.component.html',
  styleUrls: ['./pokemon.component.scss']
})
export class PokemonComponent implements OnInit {

  constructor(private jsonReader: JsonReaderService) { }

  pokemons: Pokemon[] = [];

  ngOnInit(): void {
    this.jsonReader.getPokemonList().subscribe(json => {
      let pokedex: any[]= Object.assign(json);
      this.pokemons = pokedex.map(pokemon => new Pokemon(pokemon));
    });
  }
}
