<header>
  <h1 routerLink="/">Diox974</h1>
  <nav class="navbar navbar-expand">


    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <button routerLink="/PremierOnglet">Premier Onglet</button>
        <button routerLink="/DeuxiemeOnglet">Deuxieme Onglet</button>
        <button routerLink="/Amiibo">Amiibo</button>
        <button routerLink="/Mangas">Mangas</button>
        <button routerLink="/ListePokemon">Liste Pokémon</button>
        <button routerLink="/Pokemon3G">Pokémon Sprite 3G</button>
        <button routerLink="/Pokemon4G">Pokémon Sprite 4G</button>
        <button routerLink="/Tetris">Tetris</button>
        <button routerLink="/Autres">Autres</button>
        <mat-slide-toggle class="example-margin" [color]="color" (change)="isButtonThemeChecked($event.checked)">
          <div class="theme-name">{{themeName}}</div>
        </mat-slide-toggle>
      </div>
    </div>
  </nav>
</header>