<h1>Liste des Amiibos</h1>

<div class="container">
    <div class="row">
        <div *ngFor="let amiibo of amiiboList" class="col-sm-3">
            <div *ngIf="amiiboList[amiiboList.length-7] != amiibo; else elseBlock">
                <app-amiibo-holder [amiibo]="amiibo"></app-amiibo-holder>
            </div>
            <ng-template #elseBlock>
                <app-infinite-scroll (scrolled)="onScroll()">
                    <app-amiibo-holder [amiibo]="amiibo"></app-amiibo-holder>
                </app-infinite-scroll>
            </ng-template>
        </div>
    </div>
</div>