import { Component, Input, OnInit } from '@angular/core';
import { Amiibo } from "src/app/common/model/amiibo";
import { ThemeService } from 'src/app/common/theme/theme.service';

@Component({
  selector: 'app-amiibo-holder',
  templateUrl: './amiibo-holder.component.html',
  styleUrls: ['./amiibo-holder.component.scss']
})
export class AmiiboHolderComponent implements OnInit {

  @Input() amiibo: Amiibo = new Amiibo({});
  theme: string = "";

  constructor(private themeService: ThemeService) {
    this.theme = this.themeService.theme.bootstrap;
    this.themeService.getNewTheme.subscribe(_theme => this.theme = _theme.bootstrap);
  }

  ngOnInit(): void {
  }
}
