export class Sprite3G {
    static readonly GEN1 = new Sprite3G(1, 'GameBoyAdvance-PokemonEmerald-Pokemon1stGeneration', 151, getDimension3G());
    static readonly GEN2 = new Sprite3G(2, 'GameBoyAdvance-PokemonEmerald-Pokemon2ndGeneration', 99, getDimension3G());
    static readonly GEN3 = new Sprite3G(3, 'GameBoyAdvance-PokemonEmerald-Pokemon3rdGeneration', 135, getDimension3G());

    // private to disallow creating other instances of this type
    private constructor(
        private readonly numero: number,
        private readonly assets: string,
        private readonly nombre: number,
        private readonly dimension: SpriteDimension
    ) { }

    toString() {
        return this.numero;
    }

    getAssets(): string {
        return '/assets/img/pkmn-sprite/' + this.assets;
    }

    getNombre(): number {
        return this.nombre;
    }

    getNumero(): number {
        return this.numero;
    }

    getDimension(): SpriteDimension {
        return this.dimension;
    }
}

export class Sprite4G {
    static readonly GEN1 = new Sprite4G(1, 'DSDSi-PokemonPlatinum-Pokemon1stGeneration', 173, getDimension4G());
    static readonly GEN2 = new Sprite4G(2, 'DSDSi-PokemonPlatinum-Pokemon2ndGeneration', 136, getDimension4G());
    static readonly GEN3 = new Sprite4G(3, 'DSDSi-PokemonPlatinum-Pokemon3rdGeneration', 158, getDimension4G());
    static readonly GEN4 = new Sprite4G(4, 'DSDSi-PokemonPlatinum-Pokemon4thGeneration', 160, getDimension4G());

    // private to disallow creating other instances of this type
    private constructor(
        private readonly numero: number,
        private readonly assets: string,
        private readonly nombre: number,
        private readonly dimension: SpriteDimension
    ) { }

    toString() {
        return this.numero;
    }

    getAssets(): string {
        return '/assets/img/pkmn-sprite/' + this.assets;
    }

    getNombre(): number {
        return this.nombre;
    }

    getNumero(): number {
        return this.numero;
    }

    getDimension(): SpriteDimension {
        return this.dimension;
    }

    isCorrectCase(x: number, y: number): boolean {
        return !((this.numero === 2 && y === 2 && x === 18)
            || (this.numero === 4 && (
                y === 0 && x === 18
                || y === 4 && x === 18
                || y === 5 && x === 18
                || y === 9 && x === 18
                || y === 15 && x === 10
                || y === 15 && x === 12
                || y === 15 && x === 14
                || y === 15 && x === 16
            )));
    }
}

export interface SpriteDimension {
    width: number;
    height: number;
    nextPokemon: number;
    nextAnimation: number;
    xInitial: number;
    yInitial: number;
    nbParLigne: number;
}

function getDimension3G(): SpriteDimension {
    return {
        width: 64,
        height: 64,
        nextPokemon: 69,
        nextAnimation: 69,
        xInitial: -5,
        yInitial: -5,
        nbParLigne: 10
    } as SpriteDimension;
}

function getDimension4G(): SpriteDimension {
    return {
        width: 80,
        height: 80,
        nextPokemon: 162,
        nextAnimation: 81,
        xInitial: -1,
        yInitial: -34,
        nbParLigne: 20
    } as SpriteDimension;
}
