export class BaseStat {
    public pv: number;
    public attaque: number;
    public defense: number;
    public attaqueSpe: number;
    public defenseSpe: number;
    public vitesse: number;

    constructor(json:any){
        this.pv = json.HP;
        this.attaque = json.Attack;
        this.defense = json.Defense,
        this.attaqueSpe = json["Sp. Attack"];
        this.defenseSpe = json["Sp. Defense"];
        this.vitesse = json.Speed;
    }

}