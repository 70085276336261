import { Component, OnInit } from '@angular/core';
import { Sprite4G, SpriteDimension } from 'src/app/common/model/generation';
import { Sprite } from 'src/app/common/model/pokemonSprite';

@Component({
  selector: 'app-pokemon4g',
  templateUrl: './pokemon4g.component.html',
  styleUrls: ['./pokemon4g.component.scss']
})
export class Pokemon4gComponent implements OnInit {

  constructor() { }

  chosenImage: string = '';
  generation: Sprite4G = Sprite4G.GEN1;
  chromaOffset: number = 0;
  listeGen: Sprite[] = [];

  ngOnInit(): void {
    this.changeAssets();
  }

  changeAssets() {
    this.chosenImage = this.generation.getAssets() + '.png';
    this.remplirListeGen(this.generation.getNombre());
  }

  remplirListeGen(nombre: number): void {
    this.listeGen = [];
    let xCpt: number = 0;
    let yCpt: number = 0;
    let dimension: SpriteDimension = this.generation.getDimension();
    for (let index = 0; index < nombre * 2; index++) {
      if (xCpt === dimension.nbParLigne) {
        xCpt = 0;
        yCpt++;
      }
      let isNotZarbi: boolean = this.isNotZarbi(xCpt, yCpt);
      let pkm: Sprite = this.genererPokemon(dimension, xCpt, yCpt, index, isNotZarbi);
      if ((index + 1) % 2 && this.isCorrectCase(xCpt, yCpt) || !isNotZarbi) {
        this.listeGen.push(pkm);
      }
      xCpt++;
    }
  }


  isCorrectCase(x: number, y: number) {
    return this.generation.isCorrectCase(x, y);
  }

  genererPokemon(dimension: SpriteDimension, x: number, y: number, index: number, isAnimated: boolean): Sprite {
    return {
      x: dimension.xInitial - x * dimension.nextPokemon,
      y: dimension.yInitial - y * (dimension.nextPokemon + 33) - this.chromaOffset,
      nb: index + 1,
      isAnimated: isAnimated
    } as Sprite;
  }

  isNotZarbi(x: number, y: number): boolean {
    return !(this.generation.getNumero() === Sprite4G.GEN2.getNumero()
      && ((y === 6 && x < 20) || (y === 7 && x < 8)));
  }

  changeGeneration(numero: string): void {
    if (Sprite4G.GEN1.getNumero() === +numero) {
      this.generation = Sprite4G.GEN1;
    } else if (Sprite4G.GEN2.getNumero() === +numero) {
      this.generation = Sprite4G.GEN2;
    } else if (Sprite4G.GEN3.getNumero() === +numero) {
      this.generation = Sprite4G.GEN3;
    } else {
      this.generation = Sprite4G.GEN4;
    }
    this.changeAssets();
  }

  changeChromatique(valeur: string): void {
    this.chromaOffset = valeur === "Normal" ? 0 : this.generation.getDimension().height + 1;
    this.changeAssets();
  }
}
