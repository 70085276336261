import { Component, OnInit } from '@angular/core';
import { Manga, Statut } from 'src/app/common/model/manga';
import { JsonReaderService } from 'src/app/service/json/json-reader.service';


@Component({
  selector: 'app-manga-list',
  templateUrl: './manga-list.component.html',
  styleUrls: ['./manga-list.component.scss']
})
export class MangaListComponent implements OnInit {

  constructor(private jsonReader: JsonReaderService) { }

  PAGE_TITLE = "Liste des Mangas";

  mangasList        : Array<Manga>  = [];
  title             : string        = this.PAGE_TITLE;

  ngOnInit(): void {
    this.jsonReader.getMangasList().subscribe(result => {
      this.mangasList = Object.assign(result);
      this.mangasList = this.mangasList.map(manga => new Manga(manga))
                                        .filter(manga => manga.statut !== Statut.soon);
      this.mangasList = this.mangasList.sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0))
    });
  }
}
