import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'premier-onglet',
  templateUrl: './premier-onglet.component.html',
  styleUrls: ['./premier-onglet.component.scss']
})
export class PremierOngletComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
