export const data = {
    apiKey: "AIzaSyA1v2s0PukSJOm66LMSfV85gmcuMVoupFI",
    authDomain: "my-project-angular-79d33.firebaseapp.com",
    projectId: "my-project-angular-79d33",
    storageBucket: "my-project-angular-79d33.appspot.com",
    messagingSenderId: "933592129170",
    databaseURL: 'https://my-project-angular-79d33-default-rtdb.europe-west1.firebasedatabase.app',
    appId: "1:933592129170:web:ed6217aef1cf52e855904b",
    measurementId: "G-D6M9Q5MWYS"
};
  