import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Amiibo } from 'src/app/common/model/amiibo';

@Injectable({
  providedIn: 'root'
})
export class JsonReaderService {

  constructor(private client: HttpClient) { }

  public getAmiibosList(): Observable<JSON> {
    return this.getListFromJSON("amiibos");
  }

  public getMangasList(): Observable<JSON> {
    return this.getListFromJSON("mangas");
  }

  public getPokemonList(): Observable<JSON> {
    return this.getListFromJSON("pokedex");
  }

  private getListFromJSON(objet: string): Observable<JSON> {
    return this.client.get<JSON>("/assets/json/" + objet + ".json");
  }

  public getAmiibosByPage(page: number): Observable<Amiibo[]> {
    return this.client.get<Amiibo[]>("/assets/json/amiibos/" + page + ".json");
  }

  public getAmiibosByPage2(page: number): Observable<JSON> {
    return this.client.get<JSON>("/assets/json/amiibos/" + page + ".json");
  }
}
