import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PremierOngletComponent } from 'src/app/page/premier-onglet/premier-onglet.component';
import { DeuxiemeOngletComponent } from 'src/app/page/deuxieme-onglet/deuxieme-onglet.component';
import { PageNotFoundComponent } from 'src/app/page/page-not-found/page-not-found-component';
import { AccueilComponent } from 'src/app/page/accueil/accueil.component';
import { AmiiboComponent } from 'src/app/page/amiibo/amiibo.component';
import { MangaListComponent } from 'src/app/page/manga-list/manga-list.component';
import { VolumeDetailComponent } from 'src/app/page/volume-detail/volume-detail.component';
import { AutreComponent } from './page/autre/autre.component';
import { PokemonComponent } from './page/pokemon/pokemon.component';
import { TetrisComponent } from './page/tetris/tetris.component';
import { Pokemon2Component } from './page/pokemon2/pokemon2.component';
import { Pokemon4gComponent } from './page/pokemon4g/pokemon4g.component';

const routes: Routes = [
  { path: '', component: AccueilComponent },
  { path: 'PremierOnglet', component: PremierOngletComponent },
  { path: 'DeuxiemeOnglet', component: DeuxiemeOngletComponent },
  { path: 'Amiibo', component: AmiiboComponent },
  { path: 'Mangas', component: MangaListComponent },
  { path: 'Mangas/:id', component: VolumeDetailComponent },
  { path: 'ListePokemon', component: PokemonComponent },
  { path: 'Pokemon3G', component: Pokemon2Component },
  { path: 'Pokemon4G', component: Pokemon4gComponent },
  { path: 'Tetris', component: TetrisComponent },
  { path: 'Autres', component: AutreComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
