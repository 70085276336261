import { Component, OnInit } from '@angular/core';
import { Tetrimino } from 'src/app/common/model/tetris';

@Component({
  selector: 'app-tetris',
  templateUrl: './tetris.component.html',
  styleUrls: ['./tetris.component.scss']
})
export class TetrisComponent implements OnInit {

  constructor() { }

  lignes: number = 22;
  colonnes: number = 10;
  grids: number[][] = [];
  tetrimino: Tetrimino = new Tetrimino();
  pieces: number[][][] = [
    this.tetrimino.I,
    this.tetrimino.O,
    this.tetrimino.T,
    this.tetrimino.L,
    this.tetrimino.J,
    this.tetrimino.Z,
    this.tetrimino.S
  ];

  ngOnInit(): void {
    this.initGrid();
    this.startGame();
  }

  async startGame() {
    let oldIndex: number = 0;

    while(1){
      let idx: number = this.getRandomArbitrary(0, 6);
      let piece: number[][] = this.pieces[idx];
      let largeurPiece: number = piece[0].length > piece[1].length 
                                  ? piece[0].length
                                  : piece[1].length;
      let positionInitiale = this.getRandomArbitrary(0, this.colonnes - largeurPiece);
      let positionFin = positionInitiale + largeurPiece;


      if(this.checkCollision(19, positionInitiale, positionFin)){
        alert("fin")
        break;
      }

      for(let chute = this.lignes - 1; chute != -1; --chute){
        if(this.checkCollision(chute, positionInitiale, positionFin)){
          break;
        }
        for(let i = positionInitiale; i < positionFin; i++){
          if(piece[0][i - positionInitiale] && this.grids[chute][i] === 0)this.grids[chute].splice(i, 1, piece[0][i - positionInitiale]);
          this.grids[chute+1]?.splice(i, 1, piece[1][i - positionInitiale]);
          this.grids[chute+2]?.splice(i, 1, 0);
        }

        await this.delay(250);
      }
    }
  }

  checkCollision(currentLine:number, positionInitiale: number, positionFin: number) : boolean {
    for(let collision = positionInitiale; collision < positionFin; collision++){
        if(this.grids[currentLine] 
          && this.grids[currentLine][collision] 
          && this.grids[currentLine][collision] !== 0 
          && this.grids[currentLine+1]
          && this.grids[currentLine+1][collision] 
          && this.grids[currentLine+1][collision] !== 0){
          return true;
        }  
    }
    return false;
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  initGrid(){
    for(let i = 0; i < this.lignes; i++){
      let row: number[] = Array(this.colonnes).fill(0);
      this.grids.push(row);
    }
  }

  getColor(value: number): string {
    let color: string = '';
    switch (value) {
      case 1:
        color = 'aqua';
        break;
      case 2:
        color = 'yellow';
        break;
      case 3:
        color = 'purple';
        break;
      case 4:
        color = 'orange';
        break;
      case 5:
        color = 'blue';
        break;
      case 6:
        color = 'red';
        break;
      case 7:
        color = 'green';
        break;
      default:
        color = 'white';
        break;
    }
    return color;
  }

  getRandomArbitrary(min:number, max:number) {
    return Math.floor(Math.random() * (max - min) + min);
  }
}
