import { Component } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Theme, ThemeService } from '../theme/theme.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {


  color: ThemePalette;
  themeName: string;

  constructor(private themeService: ThemeService) {
    this.color = 'primary';
    this.themeName = Theme.LIGHT.name;
  }


  isButtonThemeChecked(_checked: boolean): void {
    this.themeService.setNewTheme(_checked);
    this.themeName = this.themeService.theme.name;
  }
}
