<h1>Tomes de {{title}}</h1>

<button class="return-button" routerLink="/Mangas">Retour</button>

<div class="container">
    <div class="row">
        <div *ngFor="let volume of volumeList" class="col-sm-3">
            <manga-holder [manga]="volume"></manga-holder>
        </div>
    </div>
</div>