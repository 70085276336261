import { BaseStat } from "./pokemonBaseStat";

export class Pokemon {
    public id   : number;
    public nom  : string;
    public type : Array<string>;
    public base : BaseStat;
    public nomImage: string;


    constructor(json:any) {
        this.id     = json.id;
        this.nom    = json.name.french;
        this.type   = json.type;
        this.base   = new BaseStat(json.base);
        this.nomImage = this.setNomImage(json.id);
    }

    private setNomImage(id: number): string {
        if(id >= 100) return id.toString();
        if(id <10) return '00' + id;
        return '0' + id;
    }
}