import { Component, OnInit } from '@angular/core';
import { Sprite3G, SpriteDimension } from 'src/app/common/model/generation';
import { Sprite } from 'src/app/common/model/pokemonSprite';

@Component({
  selector: 'app-pokemon2',
  templateUrl: './pokemon2.component.html',
  styleUrls: ['./pokemon2.component.scss']
})
export class Pokemon2Component implements OnInit {

  constructor() { }

  chosenImage: string = '';
  generation: Sprite3G = Sprite3G.GEN1;
  shinyAssets: string = 'Normal.png';
  listeGen: Sprite[] = [];

  ngOnInit(): void {
    this.changeAssets();
  }

  changeAssets(){
    this.chosenImage = this.generation.getAssets() + this.shinyAssets;
    this.remplirListeGen(this.generation.getNombre());
  }

  remplirListeGen(nombre: number): void {
    this.listeGen = [];
    let xCpt: number = 0;
    let yCpt: number = 0;
    let dimension: SpriteDimension = this.generation.getDimension();
    for (let index = 0; index < nombre * 2; index++) {
      if (xCpt == dimension.nbParLigne) {
        xCpt = 0;
        yCpt++;
      }
      let pkm: Sprite = {
        x: dimension.xInitial - xCpt * dimension.nextPokemon,
        y: dimension.yInitial - yCpt * dimension.nextPokemon,
        nb: index + 1,
        isAnimated: true
      };
      if ((index + 1) % 2) {
        this.listeGen.push(pkm);
      }
      xCpt++;
    }
  }

  changeGeneration(numero: string): void {
    if (Sprite3G.GEN1.getNumero() === +numero) {
      this.generation = Sprite3G.GEN1;
    } else if (Sprite3G.GEN2.getNumero() === +numero) {
      this.generation = Sprite3G.GEN2;
    } else {
      this.generation = Sprite3G.GEN3;
    }
    this.changeAssets();
  }

  changeChromatique(valeur: string): void {
    this.shinyAssets = valeur + '.png';
    this.changeAssets();
  }
}
